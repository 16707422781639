<template>
  <ul>
    <component :is="resolveNavItemComponent(item)" v-for="item in items" v-if="checkPermissionMenu(item.permission)"
      :key="item.header || item.title" :item="item" />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import store from "@/store/index";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    const checkPermissionMenu = (role) => {
      const isPermission = store.state.userData.permissions?.some(
        (item) => item.name === role
      );
      return isPermission
    };

    return {
      resolveNavItemComponent,
      checkPermissionMenu,
    };
  },
};
</script>

export default [
  {
    title: "g.routes.statistics",
    icon: "statistics.svg",
    iconActive: "statistics-fill.svg",
    permission: "users",
  },
  {
    title: "g.routes.users",
    icon: "users.svg",
    iconActive: "users-fill.svg",
    permission: "users",
    children: [
      {
        title: "g.routes.users_list",
        route: "UsersList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_user",
        route: "AddUser",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.countries",
    permission: "countries",
    icon: "flag.svg",
    iconActive: "flag-fill.svg",
    children: [
      {
        title: "g.routes.countries_list",
        route: "Countries",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.routes.teachersRequests",
    icon: "teacher-request.svg",
    iconActive: "teacher-request-fill.svg",
    permission: "teachers",
    children: [
      {
        subPermission: "index",
        title: "g.routes.teachersRequests_list",
        route: "teachersRequests",
      },
    ],
  },
  {
    title: "g.routes.education_years",
    icon: "education-years.svg",
    iconActive: "education-years-fill.svg",
    permission: "edu-years",
    children: [
      {
        title: "g.routes.education_years_list",
        route: "EducationYearsList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_education_year",
        route: "AddEducationYear",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.coupons",
    icon: "coupon.svg",
    iconActive: "coupon-fill.svg",
    permission: "coupons",
    children: [
      {
        title: "g.routes.coupons_list",
        route: "couponsList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_coupon",
        route: "AddCoupon",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.education_levels",
    icon: "education-levels.svg",
    iconActive: "education-levels-fill.svg",
    permission: "edu-levels",
    children: [
      {
        title: "g.routes.education_levels_list",
        route: "EducationLevelsList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_education_levels",
        route: "AddEducationLevel",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.subjects",
    icon: "subjects.svg",
    iconActive: "subjects-fill.svg",
    permission: "subjects",
    children: [
      {
        title: "g.routes.subjects_list",
        route: "SubjectsList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_subjects",
        route: "AddSubjects",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.courses",
    icon: "course.svg",
    iconActive: "course-fill.svg",
    permission: "courses",
    children: [
      {
        title: "g.routes.courses_list",
        route: "allCoursesList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.routes.assignment_types",
    icon: "assignment-types.svg",
    iconActive: "assignment-types-fill.svg",
    permission: "assignments",
    children: [
      {
        title: "g.routes.assignment_types_list",
        route: "assignmentTypesList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_assignment_types",
        route: "addAssignmentType",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.grades",
    icon: "grades.svg",
    iconActive: "grades-fill.svg",
    permission: "grades",
    children: [
      {
        title: "g.routes.grades_list",
        route: "gradesList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_grades",
        route: "addGrade",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.board_styles",
    icon: "boards.svg",
    iconActive: "boards-fill.svg",
    permission: "board-styles",
    children: [
      {
        title: "g.routes.board_styles_list",
        route: "boardStylesList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_board_styles",
        route: "addBoardStyle",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.reports",
    icon: "report.svg",
    iconActive: "report-fill.svg",
    permission: "reports",
    children: [
      {
        title: "g.routes.reports_list",
        route: "ReportsList",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.routes.report_reasons",
    icon: "report-reason.svg",
    iconActive: "report-reason-fill.svg",
    permission: "report-reasons",
    children: [
      {
        title: "g.routes.report_reasons_list",
        route: "ReportReasonsList",
        subPermission: "index",
      },
      {
        title: "g.routes.add_report_reasons",
        route: "AddReportReasons",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.currencies",
    icon: "currency.svg",
    iconActive: "currency-fill.svg",
    permission: "roles",
    children: [
      {
        title: "g.routes.currencies_list",
        route: "Currencies",
        subPermission: "index",
      },
    ],
  },
  {
    title: "g.routes.roles",
    icon: "roles.svg",
    iconActive: "roles-fill.svg",
    permission: "roles",
    children: [
      {
        title: "g.routes.roles_list",
        route: "ListRoles",
        subPermission: "index",
      },
      {
        title: "g.routes.add_role",
        route: "AddRole",
        subPermission: "store",
      },
    ],
  },
  {
    title: "g.routes.parents_requests",
    icon: "parent-request.svg",
    iconActive: "parent-request-fill.svg",
    permission: "users",
    children: [
      {
        title: "g.routes.parents_requests_list",
        route: "parentsRequests",
        subPermission: "index",
      },
    ],
  },
];

<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    ></div>

    <locale />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <ul class="nav navbar-nav">
        <li class="nav-item mr-1">
          <div class="nav-item-circle">
            <b-link class="nav-link">
              <span>
                <img
                  :src="require('@/assets/images/futuer-icons/bell.svg')"
                  alt="bell-icon"
                  width="24"
                  height="24"
                />
              </span>
            </b-link>
          </div>
        </li>
      </ul>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-avatar
            size="40"
            variant="light-primary"
            :src="userAvatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item to="/profile" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>{{ $t("g.profile") }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("g.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import helpers from "@/composables/helper";
import Locale from "../../@core/layouts/components/app-navbar/components/Locale.vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    userAvatar() {
      return this.$helpers.filterImages(
        this.$helpers.uiAvatar(
          this.$store.state?.userData?.userData?.first_name
        ),
        "path",
        this.$store.state?.userData?.userData?.media
      );
    },
  },

  methods: {
    logout() {
      localStorage.clear();
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Good Bye`,
          icon: "CheckCircleIcon",
          variant: "warning",
          text: `You have successfully logged out!`,
        },
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 500);
    },
  },
};
</script>

<style>
.nav-item-circle {
  background-color: #e0e0e0;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

[dir] .header-navbar.floating-nav {
  margin: 0 !important;
}

.vertical-layout.vertical-menu-modern.menu-collapsed
  .header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - 4.4rem - 26px) !important;
}

.header-navbar.floating-nav {
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) - 202px) !important;
}

@media (max-width: 1199.98px) {
  .header-navbar.floating-nav {
    width: calc(100vw - (100vw - 100%)) !important;
  }
}
</style>

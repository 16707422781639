<template>
  <li
    v-if="
      canViewVerticalNavMenuLink(item) &&
      checkSubPermissionMenu(role, item.subPermission)
    "
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useAclUtils } from "@core/libs/acl";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import store from "@/store/index";

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
    role: String,
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    const checkSubPermissionMenu = (role, sub) => {
      const isPermissions =
        store.state.userData?.permissions?.find((item) => item.name === role) ??
        [];
      const isSubPermission = isPermissions?.subPermissions?.some(
        (item) => item.name === sub && item.is_checked
      );

      return isSubPermission;
    };

    return {
      isActive,
      linkProps,
      updateIsActive,
      canViewVerticalNavMenuLink,
      t,
      checkSubPermissionMenu,
    };
  },
};
</script>
